import { ErrorPage } from '@components/ErrorPage/ErrorPage';
import { FullPageLoading } from '@components/Loader';
import { ModuleSkeleton } from '@components/Skeleton/Layout-Skeleton/ModuleSkeleton';
import { Box } from '@getonnet/tixio-ui-core';
import { sentryCreateBrowserRouter } from '@utils/sentry-browser-router';
import { RouterProvider } from 'react-router-dom';
import { RouteLazyFactory } from './RouteLazyFactory';

const components = {
  LoginLazy: RouteLazyFactory({
    factory: () => import('@/containers/OnBoarding/pages/signin/Signin').then((m) => ({ default: m.Signin })),
    name: 'Signin',
    dontAllowWhileloggedIn: false,
    fallback: <ModuleSkeleton />,
  }),
  ConnectedTeamsLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/connected-workspaces/ConnectedWorkspaces').then((m) => ({
        default: m.ConnectedWorkspaces,
      })),
    name: 'ConnectedWorkspaces',
  }),
  SocialLoginLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/social-login/SocialLogin').then((m) => ({
        default: m.SocialLogin,
      })),
    name: 'SocialLogin',
  }),
  ForgotPasswordLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/forgot-password/ForgotPassword').then((m) => ({
        default: m.ForgotPassword,
      })),
    name: 'ForgotPasswordLazy',
  }),
  ResetPasswordLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/reset-password/ResetPassword').then((m) => ({
        default: m.ResetPassword,
      })),
    name: 'ResetPasswordLazy',
  }),

  PublicShareBoardLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Board/pages/public-share/BoardPublicShare').then((m) => ({
        default: m.BoardPublicShare,
      })),
    name: 'BoardPublicShare',
  }),
  PublicShareWikiLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Wiki/pages/public-share/WikiPublicShare').then((m) => ({ default: m.WikiPublicShare })),
    name: 'PublicShareWikiLazy',
  }),
  PublicShareWhiteboardLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Whiteboard/pages/public-share/WhiteboardPublicShare').then((m) => ({
        default: m.WhiteboardPublicShare,
      })),
    name: 'PublicShareWhiteboardLazy',
  }),

  //signup routes
  SignupLazy: RouteLazyFactory({
    factory: () => import('../containers/OnBoarding/pages/signup/Signup').then((m) => ({ default: m.Signup })),
    name: 'SignupLazy',
  }),
  EmailConfirmationLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/email-confirmation/EmailConfirmation').then((m) => ({
        default: m.EmailConfirmation,
      })),
    name: 'EmailConfirmationLazy',
  }),
  SignupVerifyMailLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/verify-email/SignupVerifyEmail').then((m) => ({
        default: m.SignupVerifyMail,
      })),
    name: 'SignupVerifyMailLazy',
  }),
  UserInfoLazy: RouteLazyFactory({
    factory: () => import('../containers/OnBoarding/pages/user-info/UserInfo').then((m) => ({ default: m.UserInfo })),
    name: 'UserInfoLazy',
  }),
  AcceptInvitationLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/accept-invitation/AcceptInvitation').then((m) => ({
        default: m.AcceptInvitation,
      })),
    name: 'AcceptInvitationLazy',
  }),
  AcceptInvitationSuccessLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/accept-invitation/AcceptInvitationSuccess').then((m) => ({
        default: m.AcceptInvitationSuccess,
      })),
    name: 'AcceptInvitationSuccess',
  }),
  AcceptInviteToItemLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/ConnectedLinks/components/AcceptInviteToItem/AcceptInviteToItem').then((m) => ({
        default: m.AcceptInviteToItem,
      })),
    name: 'AcceptInviteToItemLazy',
  }),
  AppRedirectionLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/app-info/AppRedirection').then((m) => ({
        default: m.AppRedirection,
      })),
    name: 'AppRedirectionLazy',
  }),
  WorkspaceInfoLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/OnBoarding/pages/workspace-info/WorkspaceInfo').then((m) => ({ default: m.WorkspaceInfo })),
    name: 'WorkspaceInfoLazy',
  }),

  //archive routes
  ArchiveBoardLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Archive/routes/BoardArchiveRoutes').then((m) => ({ default: m.BoardArchiveRouter })),
    name: 'ArchiveBoardLazy',
    fallback: <ModuleSkeleton />,
  }),
  ArchiveWikiLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Archive/routes/WikiArchiveRoutes').then((m) => ({ default: m.WikiArchiveRouter })),
    name: 'ArchiveWikiLazy',
    fallback: <ModuleSkeleton />,
  }),

  RequestAccessItemLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/ConnectedLinks/components/RequestAccessItem/RequestAccessItem').then((m) => ({
        default: m.RequestAccessItem,
      })),
    name: 'RequestAccessItemLazy',
  }),
  //appsumo routes
  AppSumoLazy: RouteLazyFactory({
    factory: () => import('../containers/AppSumo/AppSumo').then((m) => ({ default: m.AppSumo })),
    name: 'AppSumoLazy',
  }),
  // AppSumoLoginLazy: RouteLazyFactory({
  //   factory: () => import('../containers/AppSumo/pages/login/AppSumoLogin').then((m) => ({ default: m.AppSumoLogin })),
  //   name: 'AppSumoLoginLazy',
  // }),
  // AppSumoEmailLazy: RouteLazyFactory({
  //   factory: () =>
  //     import('../containers/AppSumo/pages/verification/SignUpVerifyEmail').then((m) => ({
  //       default: m.SignUpVerifyEmail,
  //     })),
  //   name: 'AppSumoEmailLazy',
  // }),
  // AppSumoEmailConfirmationLazy: RouteLazyFactory({
  //   factory: () =>
  //     import('../containers/AppSumo/pages/verification/SignUpVerifyCode').then((m) => ({
  //       default: m.SignUpVerifyCode,
  //     })),
  //   name: 'AppSumoEmailConfirmationLazy',
  // }),
  // AppSumoUserInfoLazy: RouteLazyFactory({
  //   factory: () =>
  //     import('../containers/AppSumo/pages/userInfo/AppSumoUserInfo').then((m) => ({ default: m.AppSumoUserInfo })),
  //   name: 'AppSumoUserInfoLazy',
  // }),
  // RedeemNewUserLazy: RouteLazyFactory({
  //   factory: () => import('../containers/AppSumo/components/RedeemNewUser').then((m) => ({ default: m.RedeemNewUser })),
  //   name: 'RedeemNewUserLazy',
  // }),
  // RedeemExistingUserLazy: RouteLazyFactory({
  //   factory: () =>
  //     import('../containers/AppSumo/components/RedeemExistingUser').then((m) => ({ default: m.RedeemExistingUser })),
  //   name: 'RedeemExistingUserLazy',
  // }),
  //private routes
  AuthenticatedAppLazy: RouteLazyFactory({
    factory: () =>
      import('./AuthenticatedApp').then((m) => ({
        default: m.AuthenticatedApp,
      })),
    name: 'AuthenticatedAppLazy',
    fallback: (
      <FullPageLoading
        hint="Checking your login status"
        progress={60}
      />
    ),
  }),
  BoardRouterLazy: RouteLazyFactory({
    factory: () => import('../containers/Board/routes/BoardRoutes').then((m) => ({ default: m.BoardRouter })),
    name: 'BoardRouterLazy',
    fallback: <ModuleSkeleton />,
  }),
  WikiRouterLazy: RouteLazyFactory({
    factory: () => import('../containers/Wiki/routes/WikiRoutes').then((m) => ({ default: m.MemoizedWikiRouter })),
    name: 'WikiRouterLazy',
    fallback: <ModuleSkeleton />,
  }),
  FilesLazy: RouteLazyFactory({
    factory: () => import('../containers/Files/routes/FilesRoutes').then((m) => ({ default: m.FilesRouter })),
    name: 'FilesLazy',
    fallback: <ModuleSkeleton />,
  }),
  ChatLazy: RouteLazyFactory({
    factory: () => import('../containers/Chat/routes/ChatRoutes').then((m) => ({ default: m.ChatRouter })),
    name: 'ChatLazy',
    fallback: <ModuleSkeleton />,
  }),
  TaskModuleLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/TaskModule/routes/TaskModuleRoutes').then((m) => ({ default: m.TaskModuleRouter })),
    name: 'TaskModuleLazy',
    fallback: <ModuleSkeleton />,
  }),
  ScriptRouterLazy: RouteLazyFactory({
    factory: () => import('../containers/Script/routes/ScriptRoutes').then((m) => ({ default: m.ScriptRouter })),
    name: 'ScriptRouterLazy',
    fallback: <ModuleSkeleton />,
  }),
  WhiteboardRouterLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Whiteboard/routes/WhiteboardRoutes').then((m) => ({ default: m.WhiteboardRouter })),
    name: 'WhiteboardRouterLazy',
    fallback: <ModuleSkeleton />,
  }),
  HrModuleRouterLazy: RouteLazyFactory({
    factory: () => import('../containers/HrModule/routes/HrModuleRoutes').then((m) => ({ default: m.HrModuleRouter })),
    name: 'HrModuleRouterLazy',
    fallback: <ModuleSkeleton />,
  }),
  AnalyticsLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Analytics/routes/AnalyticsRoutes').then((m) => ({ default: m.AnalyticsRouter })),
    name: 'AnalyticsLazy',
  }),
  SettingsRouterLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Settings-And-Members/routes/SettingsRoutes').then((m) => ({ default: m.SettingsRouter })),
    name: 'SettingsRouterLazy',
  }),
  ProfileDetailsRouterLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Global-Components/ProfileDetails/ProfileDetailsIndex').then((m) => ({
        default: m.ProfileDetailsIndex,
      })),
    name: 'ProfileDetailsRouterLazy',
  }),
  CalendarRedirectLazy: RouteLazyFactory({
    factory: () =>
      import('../containers/Settings-And-Members/pages/calendar/CalendarRedirectHandler').then((m) => ({
        default: m.CalendarRedirectHandler,
      })),
    name: 'CalendarRedirectLazy',
  }),
  WorkspaceLayoutLazy: RouteLazyFactory({
    factory: () =>
      import('./Layouts/WorkspaceLayout').then((m) => ({
        default: m.WorkspaceLayout,
      })),
    name: 'WorkspaceLayoutLazy',
    fallback: (
      <FullPageLoading
        progress={80}
        hint="Hydrating your workspace data"
      />
    ),
  }),
  LayoutWithPrimaryLeftBarLazy: RouteLazyFactory({
    factory: () =>
      import('./Layouts/LayoutWithPrimaryLeftBar').then((m) => ({
        default: m.LayoutWithPrimaryLeftBar,
      })),
    name: 'LayoutWithPrimaryLeftBarLazy',
    fallback: (
      <FullPageLoading
        progress={100}
        hint="Almost done loading your workspace"
      />
    ),
  }),
  DemoPage: RouteLazyFactory({
    factory: () => import('../containers/Demo/DemoPage'),
    name: 'DemoPage',
    fallback: <FullPageLoading />,
  }),

  // FilesRedirectHandlerLazy: RouteLazyFactory({
  //   factory: () =>
  //     import('../containers/Files/routes/Redirect').then((m) => ({
  //       default: m.FilesRedirectHandler,
  //     })),
  //   name: 'FilesRedirectHandlerLazy',
  // }),
};

export const Routes = () => {
  const router = sentryCreateBrowserRouter([
    //--- private routes ---
    {
      path: '/',
      Component: components.AuthenticatedAppLazy,
      children: [
        {
          path: '/connected-workspaces',
          Component: components.ConnectedTeamsLazy,
        },
        {
          path: '/create-workspace',
          Component: components.WorkspaceInfoLazy,
        },
        {
          path: '/appsumo',
          Component: components.AppSumoLazy,
        },
        {
          Component: components.WorkspaceLayoutLazy,
          children: [
            {
              // primary left bar routes
              Component: components.LayoutWithPrimaryLeftBarLazy,
              children: [
                // with primary sidebar
                {
                  path: '/board/*',
                  Component: components.BoardRouterLazy,
                },
                {
                  path: '/wiki/*',
                  Component: components.WikiRouterLazy,
                },
                {
                  path: '/files/*',
                  Component: components.FilesLazy,
                },
                {
                  path: '/chat/*',
                  Component: components.ChatLazy,
                },
                {
                  path: '/task-module/*',
                  Component: components.TaskModuleLazy,
                },
                {
                  path: '/script/*',
                  Component: components.ScriptRouterLazy,
                },
                {
                  path: '/canvas/*',
                  Component: components.WhiteboardRouterLazy,
                },
                {
                  path: '/hr/*',
                  Component: components.HrModuleRouterLazy,
                },
                {
                  path: '/analytics',
                  Component: components.AnalyticsLazy,
                },
                {
                  path: '/archive/board',
                  Component: components.ArchiveBoardLazy,
                },
                {
                  path: '/archive/wiki',
                  Component: components.ArchiveWikiLazy,
                },
                {
                  path: '/no-access/:id?',
                  Component: components.RequestAccessItemLazy,
                },
              ],
            },
            {
              path: '/settings/*',
              Component: components.SettingsRouterLazy,
            },
            {
              path: '/profile/:id',
              Component: components.ProfileDetailsRouterLazy,
            },
          ],
        },
        // routes that don't require primary left bar such as connected teams, analytics, settings and members
      ],
    },
    /* ====================== public routes============= */
    {
      path: '/signin',
      Component: components.LoginLazy,
    },
    {
      path: '/user/oauth-callback',
      Component: components.SocialLoginLazy,
    },
    // {
    //   path: '/files/oauth-callback',
    //   Component: components.FilesRedirectHandlerLazy,
    // },
    {
      path: '/forgot-password',
      Component: components.ForgotPasswordLazy,
    },
    {
      path: '/reset-password/*',
      Component: components.ResetPasswordLazy,
    },
    /* ====================== public share routes============= */

    {
      path: '/share/board/:boardId',
      Component: components.PublicShareBoardLazy,
    },
    {
      path: '/share/wiki/:id',
      Component: components.PublicShareWikiLazy,
    },
    {
      path: '/share/canvas/:canvasID',
      Component: components.PublicShareWhiteboardLazy,
    },
    // signup routes
    {
      path: '/signup/app-info',
      Component: components.AppRedirectionLazy,
    },
    {
      path: '/signup',
      Component: components.SignupLazy,
    },
    {
      path: '/signup/email-confirmation',
      Component: components.EmailConfirmationLazy,
    },
    {
      path: '/sign-up/email',
      Component: components.SignupVerifyMailLazy,
    },
    {
      path: '/signup/user-info',
      Component: components.UserInfoLazy,
    },
    {
      path: '/signup/workspace-info',
      Component: components.WorkspaceInfoLazy,
    },

    {
      path: '/accept-invite/:id',
      Component: components.AcceptInvitationLazy,
    },
    {
      path: '/accept-invitation-success',
      Component: components.AcceptInvitationSuccessLazy,
    },

    {
      path: '/accept-invite-to-item',
      Component: components.AcceptInviteToItemLazy,
    },

    {
      path: '/signup/app-info',
      Component: components.AppRedirectionLazy,
    },
    // //appsumo routes
    // {
    //   path: '/appsumo-onboarding',
    //   Component: components.AppSumoLoginLazy,
    // },
    // {
    //   path: '/appsumo-onboarding/email',
    //   Component: components.AppSumoEmailLazy,
    // },
    // {
    //   path: '/appsumo-onboarding/email-confirmation',
    //   Component: components.AppSumoEmailConfirmationLazy,
    // },
    // {
    //   path: '/appsumo-onboarding/user-info',
    //   Component: components.AppSumoUserInfoLazy,
    // },
    // {
    //   path: '/appsumo/redeem-new-user',
    //   Component: components.RedeemNewUserLazy,
    // },
    // {
    //   path: '/appsumo/redeem-existing-user',
    //   Component: components.RedeemExistingUserLazy,
    // },
    //calendar routes
    {
      path: '/calendar/callback',
      Component: components.CalendarRedirectLazy,
    },

    {
      path: '/demo',
      Component: components.DemoPage,
    },

    {
      path: '*',
      element: (
        <Box sx={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
          <ErrorPage title="404 not found!" />
        </Box>
      ),
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
};
